<template>
  <div class="marketplace-welcome">
    <div class="marketplace-welcome__container">
      <div class="marketplace-welcome__img">
        <img src="/static/images/marketplace_welcome.png" alt="" />
      </div>
      <div class="marketplace-welcome__info">
        <h1 v-if="data.title" class="marketplace-welcome__title">{{ data.title }}</h1>
        <div v-if="data.description" class="marketplace-welcome__description">
          <EditorJSComponent :text="data.description" />
        </div>
        <button @click="showLoginModal" class="btn btn--lg btn--main">Зарегистрироваться</button>
      </div>
    </div>
  </div>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";

export default {
  name: "MarketplaceWelcomeComponent",
  components: { EditorJSComponent },
  props: {
    data: Object,
  },
  methods: {
    showLoginModal() {
      this.$store.state._modals.push({
        component: LoginModal,
        options: {},
      });
    },
  },
};
</script>

<style lang="stylus">
.marketplace-welcome {
  width 100%
  padding 0 15px 26px
  display flex
  justify-content center

  &__container {
    width 100%
    max-width var(--main_width)
    display grid
    grid-template-columns 547fr 675fr
    align-items center
    gap 74px
    +below(880px) {
      grid-template-columns 1fr
      gap 16px
    }
  }

  &__img {
    width 100%
    max-width 547px
    margin 0 auto

    img {
      width 100%
      height 100%
      object-fit contain
      object-position center
    }
  }

  &__info {
    display flex
    flex-direction column
    align-items flex-start
    gap 16px
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 50px;
    color: var(--gray-900)
    margin 0
  }

  &__description {
    font-size: 0.875em
    line-height: 20px;
    color: var(--gray-500)
  }
}
</style>
