<template>
  <div v-if="data" class="marketplace-cell">
    <span class="marketplace-cell__icon" v-html="data.icon"> </span>
    <span class="marketplace-cell__title">{{ data.title }}</span>
  </div>
</template>

<script>
export default {
  name: "MarketplaceCellComponent",
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus">
.marketplace-cell {
  display flex
  flex-direction column
  align-items center
  gap 16px
  text-align center;
  width 100%
  max-width 314px
  padding 24px
  +below(420px) {
    max-width 100%
  }

  &__icon {
    flex-shrink 0
    display flex
    align-items center
    justify-content center
    padding 24px
    background var(--blue-100)
    border-radius 50%

    svg {
      width 32px
      height 32px

      path {
        fill var(--green)
      }
    }
  }

  &__title {
    font-size: 0.875em
    line-height: 20px;
    color: var(--gray-500)
  }
}
</style>
