<template>
  <div v-if="numbers && numbers.length" class="numbers-container">
    <div class="numbers__list">
      <div v-for="(item, i) in numbers" :key="i" class="numbers__item">
        <span class="numbers__item-value">
          {{ item.value.toLocaleString("ru-RU", { maximumFractionDigits: 0 }) || 0 }}
        </span>
        <span class="numbers__item-title">{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumbersComponent",
  props: {
    numbers: Array,
  },
};
</script>

<style lang="stylus">
.numbers {
  &-container {
    display flex
    flex-direction column
    align-items center
    padding 0 15px
  }

  &__list {
    width 100%
    max-width var(--main_width)
    display flex
    flex-flow row wrap
    align-items center
    justify-content center
    gap 24px
  }

  &__item {
    width calc(25% - 24px)
    display flex
    flex-direction column
    align-items center
    justify-content center
    text-align center;
    padding 24px
    border-radius var(--main_radius)
    background var(--white)
    +below(768px) {
      width calc(50% - 24px)
    }
    +below(420px) {
      width 100%
    }

    &-value {
      font-size: 1.5em
      line-height: 32px;
      color: var(--orange-500)
    }
  }
}
</style>
