<template>
  <div v-if="list && list.length" class="marketplace-list__container">
    <div class="marketplace-list__inner">
      <h2 v-if="title" class="marketplace-list__title">{{ title }}</h2>
      <ul class="marketplace-list">
        <li v-for="(item, i) in list" :key="i" class="marketplace-list__item">
          <div v-if="item.icon" class="marketplace-list__item-icon" v-html="item.icon">
          </div>
          <div v-else class="marketplace-list__item-number">
            {{ i + 1 }}
          </div>
          <div class="marketplace-list__item-info">
            <h3 class="marketplace-list__item-title">{{ item.title }}</h3>
            <span class="marketplace-list__item-description">
              <EditorJSComponent :text="item.description" />
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "MarketplaceListComponent",
  components: { EditorJSComponent },
  props: {
    title: String,
    list: Array,
  },
};
</script>

<style lang="stylus">
.marketplace-list {
  display flex
  flex-direction column
  gap 8px

  &__container {
    display flex
    justify-content center
    width 100%
    padding 0 15px
  }

  &__inner {
    width 100%
    max-width 856px
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 2.25em
    line-height: 50px;
    margin-bottom 24px
    text-align center
  }

  &__item {
    display flex
    align-items center
    gap 16px
    padding 24px
    background var(--white)
    border-radius var(--main_radius)
    +below(520px) {
      flex-direction column
      align-items flex-start
    }

    &-number {
      flex-shrink 0
      width 64px
      height 64px
      border-radius 50%
      border 1px solid var(--green)
      font-size: 1.5em
      line-height: 32px;
      color var(--green)
      display flex
      align-items center
      justify-content center
    }

    &-icon {
      flex-shrink 0
    }

    &-info {
      display flex
      flex-direction column
      gap 4px
    }

    &-title {
      font-style: normal;
      font-weight: 400;
      font-size: 1.5em
      line-height: 32px;
      margin 0
    }

    &-description {
      font-size: 0.875em
      line-height: 20px;
      color: var(--gray-500)
    }
  }
}
</style>
