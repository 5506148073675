<template>
  <div id="claim" class="marketplace-claim__container">
    <div class="marketplace-claim">
      <h2 class="marketplace-claim__title">Заявка на подключение</h2>
      <form @submit.prevent="claimCreate" class="marketplace-claim__form form">
        <SelectComponent
          v-model="form.category_id.value"
          :options="categories"
          :errors="form.category_id.error ? [form.category_id.error] : []"
          label-name="title"
          title="Выбор категории"
          selectTitle="Выбор категории"
        />
        <InputComponent
          v-model.trim="form.title.value"
          :error="form.title.error"
          title="Наименование компании или магазина"
          placeholder="Наименование компании или магазина"
        />
        <InputComponent v-model.trim="form.inn.value" :error="form.inn.error" title="ИНН" placeholder="ИНН" />
        <InputComponent
          v-model.trim="form.fio.value"
          :error="form.fio.error"
          title="ФИО руководителя магазина"
          placeholder="ФИО руководителя магазина"
        />
        <InputComponent
          v-model.trim="form.email.value"
          :error="form.email.error"
          title="Электронная почта"
          placeholder="Электронная почта"
        />
        <InputComponent
          v-model="form.phone.value"
          :error="form.phone.error"
          title="Номер телефона"
          placeholder="Номер телефона"
          :mask="phoneMask"
        />
        <SelectComponent
          v-model="form.city_id.value"
          :options="cities"
          :errors="form.city_id.error ? [form.city_id.error] : []"
          label-name="title"
          title="Город нахождения головного офиса"
          selectTitle="Город нахождения головного офиса"
        />
        <CheckboxComponent :checked="agree" @change="agree = !agree">
          <div>
            Согласен с условиями
            <a href="/files/terms.pdf" target="_blank">Правил пользования торговой площадкой</a> и
            <a href="/files/terms.pdf" target="_blank">правилами возврата</a>
          </div>
        </CheckboxComponent>
        <button type="submit" class="btn btn--lg btn--main" :disabled="!agree">
          <FileIconComponent v-if="loading" name="loading" />
          <template v-else>Отправить</template>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
import FileIconComponent from "components/FileIconComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import CLAIM_SHOP_CREATE from "@/graphql/mutations/ClaimShopCreate.graphql";

export default {
  name: "MarketplaceClaimCreateComponent",
  data() {
    return {
      loading: false,
      agree: true,
      form: {
        category_id: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        title: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        inn: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        fio: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        phone: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        city_id: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
      },
    };
  },
  computed: {
    cities() {
      return this.$store.state.cities || [];
    },
    categories() {
      return this.$store.state.categories || [];
    },
    phoneMask() {
      const phone = this.form.phone.value;
      if (phone && phone.length > 1) {
        return phone.startsWith("+7") || phone.startsWith("+8")
          ? "+7 (###) ### - ## - ##"
          : "+90 (###) ### - ## - ##";
      }
      return "+############";
    },
  },
  methods: {
    claimCreate() {
      if (!this.loading && this.agree) {
        this.loading = true;
        this.resetErrors("form");
        this.$apollo
          .mutate({
            mutation: CLAIM_SHOP_CREATE,
            variables: {
              category_id: this.form.category_id.value ? this.form.category_id.value.id : null,
              city_id: this.form.city_id.value ? this.form.city_id.value.id : null,
              title: this.form.title.value,
              inn: this.form.inn.value,
              fio: this.form.fio.value,
              email: this.form.email.value,
              phone: this.form.phone.value ? "+" + this.form.phone.value.replace(/\D+/g, "") : null,
            },
          })
          .then(({ data }) => {
            if (data && data.ClaimShopCreate) {
              this.resetForm("form");
              this.$notify({
                title: "Отправлено",
                text: data.ClaimShopCreate.message,
                duration: 5000,
                speed: 200,
                type: "success",
              });
            }
            this.loading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
    resetErrors(form) {
      Object.keys(this[form]).forEach((key) => {
        this[form][key].error = undefined;
      });
    },
    resetForm(form) {
      Object.keys(this[form]).forEach((key) => {
        this[form][key].value = undefined;
      });
    },
  },
  components: { SelectComponent, FileIconComponent, CheckboxComponent, InputComponent },
};
</script>

<style lang="stylus">
.marketplace-claim {
  display flex
  flex-direction column
  align-items center
  width 100%
  max-width 856px
  background: var(--white)
  border-radius: 24px;
  padding 48px 0

  &__container {
    display flex
    justify-content center
    width 100%
    padding 0 15px
  }

  &__form {
    max-width 375px
    border none
    padding 0

    .btn {
      justify-content center
    }
  }
}
</style>
