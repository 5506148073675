<template>
  <div class="marketplace-page page">
    <MarketplaceWelcomeComponent :data="marketplace" />
    <NumbersComponent :numbers="numbers" />
    <MarketplaceListComponent title="Преимущества работы с нами" :list="advantages" />
    <MarketplaceSchemesComponent />
    <MarketplaceListComponent title="Что нужно для подключения" :list="whatYouNeed" />
    <MarketplaceClaimCreateComponent />
  </div>
</template>

<script>
import MarketplaceWelcomeComponent from "./components/MarketplaceWelcomeComponent.vue";
import NumbersComponent from "components/NumbersComponent.vue";
import MarketplaceListComponent from "./components/MarketplaceListComponent.vue";
import MarketplaceSchemesComponent from "./components/MarketplaceSchemesComponent.vue";
import MarketplaceClaimCreateComponent from "./components/MarketplaceClaimCreateComponent.vue";
import MARKETPLACE_PAGE from "@/graphql/pages/MarketplacePage.graphql";

export default {
  name: "MarketplacePage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: MARKETPLACE_PAGE,
      })
      .then(({ data }) => {
        store.state.marketplace_page.info = data.marketplace_page_info;
        store.state.marketplace_page.work_schemes = data.work_schemes;
        store.state.cities = data.cities;
        store.state.categories = data.categories;
        store.state.pages = data.pages;
        store.state.marketplace = data.marketplace;
        if (data.exchange_rates) store.state.exchange_rates = data.exchange_rates;
      })
      .catch(() => {});
  },
  mounted() {
    if (this.$route.hash) {
      document.querySelector(this.$route.hash).scrollIntoView({ block: "start", behavior: "smooth" });
    }
  },
  computed: {
    marketplace() {
      return this.$store.state.marketplace;
    },
    numbers() {
      return [
        {
          title: "Товаров",
          value: this.marketplace.count_products || 0,
        },
      ];
    },
    advantages() {
      return this.$store.state.marketplace_page.info.filter(
        (item) => item.type.code === this.$store.state._types.ADVANTAGE
      );
    },
    whatYouNeed() {
      return this.$store.state.marketplace_page.info.filter(
        (item) => item.type.code === this.$store.state._types.WHAT_YOU_NEED_TO_CONNECT
      );
    },
  },
  components: {
    MarketplaceWelcomeComponent,
    NumbersComponent,
    MarketplaceListComponent,
    MarketplaceSchemesComponent,
    MarketplaceClaimCreateComponent,
  },
  metaInfo() {
    return {
      title: this.marketplace.meta_title,
      description: this.marketplace.meta_description,
      keywords: this.marketplace.meta_keywords,
    };
  },
};
</script>

<style lang="stylus">
.marketplace-page {
  display grid
  grid-gap 48px
  padding 32px 0 104px
}
</style>
