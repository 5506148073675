<template>
  <div class="marketplace-schemes__container">
    <div class="marketplace-schemes">
      <h2 class="marketplace-schemes__title">Cхемы работы</h2>
      <div class="marketplace-schemes__tabs">
        <button
          v-for="(tab, i) in tabs"
          :key="i"
          class="marketplace-schemes__tab"
          :class="{ 'marketplace-schemes__tab--active': active === tab.code }"
          @click="active = tab.code"
        >
          {{ tab.title }}
        </button>
      </div>
      <div class="marketplace-schemes__content">
        <div v-for="(scheme, i) in schemes" :key="i" class="marketplace-schemes__col">
          <span v-if="scheme && scheme.length" class="marketplace-schemes__col-title">{{
            scheme[0].type_head.name
          }}</span>
          <div class="marketplace-schemes__col-blocks">
            <MarketplaceCellComponent v-for="(item, k) in scheme" :key="k" :data="item" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="payingList && payingList.length" class="marketplace-schemes__paying">
      <h2 class="marketplace-schemes__title">За что вы платите</h2>
      <div class="marketplace-schemes__paying-list">
        <MarketplaceCellComponent :data="item" v-for="(item, i) in payingList" :key="i" />
      </div>
    </div>
  </div>
</template>

<script>
import MarketplaceCellComponent from "./MarketplaceCellComponent.vue";

export default {
  name: "MarketplaceSchemesComponent",
  components: { MarketplaceCellComponent },
  data() {
    return {
      active: this.$store.state._types.SHOWCASE_SHIPPING,
    };
  },
  computed: {
    tabs() {
      return [
        {
          title: "Витрина + Доставка",
          code: this.types.SHOWCASE_SHIPPING,
        },
        {
          title: "Витрина + Фулфилмент",
          code: this.types.SHOWCASE_FULFILLMENT,
        },
        {
          title: "Закажи и забери",
          code: this.types.ORDER_AND_PICK_UP,
        },
        {
          title: "Доставка силами продавца",
          code: this.types.DELIVERY_SELLER,
        },
      ];
    },
    schemes() {
      let activeWorkSchemes = this.$store.state.marketplace_page.work_schemes.filter(
        (item) => item.type.code === this.active
      );
      if (activeWorkSchemes && activeWorkSchemes.length) {
        return [
          activeWorkSchemes.filter((item) => item.type_head.code === this.types.SELLER),
          activeWorkSchemes.filter((item) => item.type_head.code === this.types.SASA),
          activeWorkSchemes.filter((item) => item.type_head.code === this.types.BUYER),
        ];
      }
      return [];
    },
    payingList() {
      return this.$store.state.marketplace_page.info.filter(
        (item) => item.type.code === this.$store.state._types.WHAT_YOU_PAYING
      );
    },
    types() {
      return this.$store.state._types;
    },
  },
};
</script>

<style lang="stylus">
.marketplace-schemes {
  display grid
  grid-gap 24px
  width 100%
  max-width 1006px

  &__container {
    padding 0 15px
    width 100%
    display flex
    flex-direction column
    align-items center
    gap 48px
  }

  &__title {
    margin 0
    text-align center
  }

  &__tabs {
    display flex
    width 100%
    overflow-x auto
  }

  &__tab {
    flex 1 0 auto
    background none
    border none
    padding 18px 25px
    border-bottom 1px solid var(--gray-100)
    color var(--gray-100)
    font-size: 0.875em
    line-height: 20px;
    cursor pointer
    transition var(--transition)

    &:hover {
      background var(--blue-200)
    }

    &--active {
      border-bottom 1px solid var(--accent)
      color var(--accent)
    }
  }

  &__content {
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 30px 8px
    +below(768px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(540px) {
      grid-template-columns 1fr
    }
  }

  &__col {
    display flex
    flex-direction column
    align-items center
    padding 8px

    &-title {
      font-size: 1.125em
      line-height: 28px;
      text-align center
      margin-bottom: 10px;
    }

    &-blocks {
      display flex
      flex-direction column
      gap 10px
    }
  }

  &__paying {
    display grid
    grid-gap 24px
    width 100%
    max-width 1038px

    &-list {
      padding 8px
      display grid
      grid-template-columns repeat(3, 1fr)
      justify-content center
      grid-gap 8px 40px
      +below(768px) {
        grid-template-columns repeat(2, 1fr)
        grid-column-gap 24px
      }
      +below(540px) {
        display flex
        flex-direction column
        align-items center
      }
    }
  }
}
</style>
